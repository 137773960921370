import { ComponentsProps } from '@mui/material/styles/props';

const props: ComponentsProps = {
    MuiButtonBase: {
        disableRipple: true,
    },
    MuiCardHeader: {
        titleTypographyProps: { variant: 'h6' },
    },
};

export default props;
